html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

h1 {
  font-size: 1.75em;
 font-weight: 500;

}
h2 {
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 1.1em;
}
* {
  box-sizing: border-box;
}

main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-family:
    Menlo,
    Monaco,
    Lucida Console,
    Courier New,
    monospace;
}

input[type='button'] {
  border: none;
  background: #007700;
  color: white;
  padding: 12px 12px;
  margin: 4px;
  border-radius: 4px;
  cursor: pointer;
}

input[type='submit'] {
  border: none;
  background: #007700;
  color: white;
  padding: 12px 12px;
  font-size: 1em;
  margin: 8px;
  border-radius: 4px;
  cursor: pointer;
}